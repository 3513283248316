import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const DeleteRecurringEventModal = ({ show, onHide, eventTitle, onDelete, mode = 'delete' }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {mode === 'delete' ? 'Eliminar evento recurrente' : 'Editar evento recurrente'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {mode === 'delete' 
            ? '¿Cómo deseas eliminar este evento?' 
            : '¿Cómo deseas editar este evento?'}
        </p>
        <div className="d-grid gap-2">
          <Button 
            variant="outline-primary" 
            onClick={() => onDelete('this')}
          >
            Solo esta ocurrencia
          </Button>
          <Button 
            variant="outline-primary" 
            onClick={() => onDelete('future')}
          >
            Esta y futuras ocurrencias
          </Button>
          <Button 
            variant="outline-primary" 
            onClick={() => onDelete('all')}
          >
            Todas las ocurrencias
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteRecurringEventModal; 