import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { Button, Container } from 'react-bootstrap';
import { ArrowLeft, Camera } from 'react-bootstrap-icons';
import { doc, getDoc, deleteDoc, updateDoc, collection, query, where, getDocs, addDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../config/firebase-config';
import AddEventModal from '../AddEventModal';
import '../styles/EventDetails.css';
import { RRule } from 'rrule';
import DeleteRecurringEventModal from './DeleteRecurringEventModal';
import EditRecurringEventModal from './EditRecurringEventModal';


const EventDetails = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const previousView = location.state?.previousView;
  const previousDate = location.state?.previousDate;
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [classes, setClasses] = useState([]);
  const [activeMembers, setActiveMembers] = useState([]);
  const auth = getAuth();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [pendingEdit, setPendingEdit] = useState(null);
  const [editMode, setEditMode] = useState(null); // 'single', 'future', or 'all'
  const [showEditRecurringModal, setShowEditRecurringModal] = useState(false);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          console.error('No user logged in!');
          navigate('/login');
          return;
        }

        // Parse the composite ID if it contains a timestamp
        let originalEventId = eventId;
        let occurrenceDate = null;

        if (eventId.includes('_')) {
          [originalEventId, occurrenceDate] = eventId.split('_');
          occurrenceDate = new Date(parseInt(occurrenceDate));
        }

        console.log('Fetching event with original ID:', originalEventId);

        const eventDoc = await getDoc(doc(db, 'events', originalEventId));
        
        if (!eventDoc.exists()) {
          console.log('Event not found in database');
          setError('Event not found');
          return;
        }

        const eventData = eventDoc.data();
        
        // Verify the event belongs to the current user
        if (eventData.createdBy !== user.uid) {
          setError('Unauthorized access');
          return;
        }

        // Handle both string dates and Firestore timestamps
        const parseEventDate = (dateValue) => {
          if (dateValue && typeof dateValue.toDate === 'function') {
            return dateValue.toDate();
          }
          return new Date(dateValue);
        };

        // If this is a specific occurrence of a recurring event
        if (occurrenceDate && eventData.rrule) {
          // Check if there's an exception for this occurrence
          const exception = eventData.exceptions?.[occurrenceDate.toISOString()];
          
          if (exception) {
            // Use the exception data
            eventData.start = exception.start;
            eventData.end = exception.end;
            // Merge other exception properties
            Object.assign(eventData, exception);
          } else {
            // Use the occurrence date but keep other properties
            eventData.start = occurrenceDate.toISOString();
            const duration = new Date(eventData.end) - new Date(eventData.start);
            eventData.end = new Date(occurrenceDate.getTime() + duration).toISOString();
          }
        }

        // Parse recurrence from rrule
        const recurrence = eventData.rrule ? getRecurrenceFromRRule(eventData.rrule) : null;

        setEvent({
          id: originalEventId,
          ...eventData,
          start: parseEventDate(eventData.start),
          end: parseEventDate(eventData.end),
          recurrence,
          isOccurrence: !!occurrenceDate,
          occurrenceDate: occurrenceDate,
          originalStart: occurrenceDate ? parseEventDate(eventData.start) : null
        });
      } catch (error) {
        console.error('Detailed error fetching event:', error);
        setError('Error loading event details');
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, [eventId, navigate, auth]);

  useEffect(() => {
    const fetchClassesAndMembers = async () => {
      try {
        // Fetch classes
        const classesSnapshot = await getDocs(query(
          collection(db, 'classes'),
          where('createdBy', '==', auth.currentUser.uid)
        ));
        setClasses(classesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })));

        // Fetch members
        const membersSnapshot = await getDocs(query(
          collection(db, 'members'),
          where('createdBy', '==', auth.currentUser.uid)
        ));
        setActiveMembers(membersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })));
      } catch (error) {
        console.error('Error fetching classes and members:', error);
      }
    };

    if (auth.currentUser) {
      fetchClassesAndMembers();
    }
  }, [auth.currentUser]);

  const handleDelete = () => {
    if (event.rrule) {
      setShowDeleteModal(true);
    } else {
      // Show confirmation dialog for single events
      if (window.confirm('¿Estás seguro de que deseas eliminar este evento?')) {
        handleSingleEventDelete();
      }
    }
  };

  const handleSingleEventDelete = async () => {
    try {
      await deleteDoc(doc(db, 'events', eventId));
      navigate(-1);
    } catch (error) {
      console.error('Error deleting event:', error);
      setError('Error deleting event');
    }
  };

  const handleRecurringDelete = async (option) => {
    try {
      let originalEventId = eventId;
      let occurrenceDate = null;

      if (eventId.includes('_')) {
        [originalEventId, occurrenceDate] = eventId.split('_');
        occurrenceDate = new Date(parseInt(occurrenceDate));
      }

      const eventRef = doc(db, 'events', originalEventId);
      const eventDoc = await getDoc(eventRef);

      if (!eventDoc.exists()) {
        console.error('Event not found');
        return;
      }

      const eventData = eventDoc.data();

      if (eventData.rrule) {
        // Split and clean the RRULE string properly
        const rruleLines = eventData.rrule.split('\n');
        
        // Get the first DTSTART line only
        const dtstartLine = rruleLines.find(line => line.startsWith('DTSTART:'));
        
        // Get the RRULE part and clean it
        const rruleLine = rruleLines.find(line => line.startsWith('RRULE:'));
        const rrulePart = rruleLine?.replace('RRULE:', '').replace(/DTSTART:[^;]+;/, '');
        
        if (!rrulePart) {
          console.error('No valid RRULE found in:', eventData.rrule);
          return;
        }

        switch (option) {
          case 'this':
            // Format the date to exclude in YYYYMMDDTHHMMSSZ format
            const dateToExclude = occurrenceDate || event.start;
            const formattedExdate = dateToExclude.toISOString()
              .replace(/[-:]/g, '')
              .replace(/\.\d+Z$/, 'Z');

            // Split the existing rrule into components
            const ruleComponents = eventData.rrule.split('\n');
            
            // Find existing EXDATE line if any
            const exdateIndex = ruleComponents.findIndex(line => 
              line.startsWith('EXDATE:')
            );

            if (exdateIndex >= 0) {
              // Add new date to existing EXDATE
              const exdates = ruleComponents[exdateIndex].split(':')[1].split(',');
              exdates.push(formattedExdate);
              ruleComponents[exdateIndex] = `EXDATE:${exdates.join(',')}`;
            } else {
              // Add new EXDATE line after DTSTART
              const dtstartIndex = ruleComponents.findIndex(line => 
                line.startsWith('DTSTART:')
              );
              ruleComponents.splice(dtstartIndex + 1, 0, `EXDATE:${formattedExdate}`);
            }

            // Reconstruct the complete rrule
            const updatedRrule = ruleComponents.join('\n');
            
            await updateDoc(eventRef, {
              rrule: updatedRrule
            });
            break;

          case 'future':
            if (!rrulePart) {
              console.error('Invalid RRULE format');
              return;
            }

            try {
              // Clean up the RRULE string first
              const cleanRruleStr = rrulePart.replace(/DTSTART:[^;]+;/, '');
              
              // Parse the original rule to get its frequency
              const originalRule = RRule.fromString(cleanRruleStr);
              const cutoffDate = occurrenceDate || event.start;
              
              // Format the cutoff date
              const formattedUntil = cutoffDate.toISOString()
                .replace(/[-:]/g, '')
                .replace(/\.\d+Z$/, 'Z');
              
              // Create new rule with UNTIL, preserving the original frequency
              const updatedRule = new RRule({
                freq: originalRule.options.freq || RRule.DAILY, // Default to DAILY if no freq
                interval: originalRule.options.interval || 1,
                until: new Date(cutoffDate),
                wkst: RRule.MO,
                // Don't include time components in the rule
                byhour: null,
                byminute: null,
                bysecond: null
              });

              // Get the original DTSTART
              const originalDtstart = dtstartLine || `DTSTART:${event.start.toISOString()
                .replace(/[-:]/g, '')
                .replace(/\.\d+Z$/, 'Z')}`;

              // Preserve existing EXDATE if any
              const existingExdates = rruleLines.filter(line => 
                line.startsWith('EXDATE:')
              );

              // Create the RRULE string ensuring FREQ is first
              const ruleString = updatedRule.toString();
              const cleanRruleString = `FREQ=${ruleString.split('FREQ=')[1]}`;

              // Reconstruct the complete RRULE string
              const newRruleString = [
                originalDtstart,
                ...existingExdates,
                `RRULE:${cleanRruleString}`
              ].join('\n');

              console.log('New RRULE string:', newRruleString); // Debug log

              await updateDoc(eventRef, {
                rrule: newRruleString
              });
            } catch (error) {
              console.error('Error parsing RRULE:', error);
              console.error('Original RRULE part:', rrulePart);
              throw new Error('Invalid recurrence rule format');
            }
            break;

          case 'all':
            await deleteDoc(eventRef);
            break;
        }
      } else {
        await deleteDoc(eventRef);
      }

      navigate(-1);
    } catch (error) {
      console.error('Error handling recurring event operation:', error);
      setError('Error updating/deleting recurring event');
    }
  };

  const handleEditSave = async (updatedEventData) => {
    try {
      const eventRef = doc(db, 'events', eventId);
      const eventDoc = await getDoc(eventRef);
      const eventData = eventDoc.data();

      // Get the selected member and class
      const selectedMember = activeMembers.find(m => m.id === updatedEventData.memberId);
      const selectedClass = classes.find(c => c.id === updatedEventData.classId);

      // Convert start and end to proper Date objects if they aren't already
      const startDate = typeof updatedEventData.start === 'string' ? 
        new Date(updatedEventData.start) : updatedEventData.start;
      const endDate = typeof updatedEventData.end === 'string' ? 
        new Date(updatedEventData.end) : updatedEventData.end;

      // Add member and class information to the update data
      const baseEventData = {
        ...updatedEventData,
        title: updatedEventData.event_title,
        memberName: selectedMember?.contact_name || '',
        memberId: selectedMember?.id || '',
        class_name: selectedClass?.class_name || '',
        classId: selectedClass?.id || '',
        start: startDate.toISOString(),
        end: endDate.toISOString(),
      };

      if (eventData.rrule) {
        const rruleLines = eventData.rrule.split('\n');
        const dtstartLine = rruleLines.find(line => line.startsWith('DTSTART:'));
        const rruleLine = rruleLines.find(line => line.startsWith('RRULE:'));
        const exdateLines = rruleLines.filter(line => line.startsWith('EXDATE:'));

        switch (editMode) {
          case 'single':
            // Create an exception for this occurrence
            const occurrenceDate = event.occurrenceDate || event.start;
            const formattedDate = occurrenceDate.toISOString()
              .replace(/[-:]/g, '')
              .replace(/\.\d+Z$/, 'Z');

            // Add to EXDATE
            const newExdate = `EXDATE:${formattedDate}`;
            const updatedRrule = [
              dtstartLine,
              ...exdateLines,
              newExdate,
              rruleLine
            ].join('\n');

            // Create a new single event for this occurrence
            const singleEventData = {
              ...eventData,
              ...baseEventData,
              rrule: null,
              recurrence: null
            };

            // Remove any recurrence-related fields
            delete singleEventData.exceptions;
            delete singleEventData.exdates;
            delete singleEventData.recurrenceEndDate;

            await Promise.all([
              updateDoc(eventRef, { rrule: updatedRrule }),
              addDoc(collection(db, 'events'), singleEventData)
            ]);
            break;

          case 'future':
            // Update current series
            const untilDate = event.occurrenceDate || event.start;
            const rule = RRule.fromString(rruleLine.replace('RRULE:', ''));
            
            const updatedRule = new RRule({
              ...rule.options,
              until: new Date(untilDate)
            });

            await updateDoc(eventRef, {
              rrule: [
                dtstartLine,
                ...exdateLines,
                `RRULE:${updatedRule.toString()}`
              ].join('\n')
            });

            // Create new series
            const newSeriesData = {
              ...eventData,
              ...baseEventData
            };

            if (updatedEventData.recurrence && updatedEventData.recurrence !== 'none') {
              newSeriesData.rrule = generateNewRRule(updatedEventData);
            }
            
            await addDoc(collection(db, 'events'), newSeriesData);
            break;

          case 'all':
            // Update the entire series
            const allSeriesData = {
              ...eventData,
              ...baseEventData
            };

            if (updatedEventData.recurrence && updatedEventData.recurrence !== 'none') {
              allSeriesData.rrule = generateNewRRule(updatedEventData);
            } else {
              delete allSeriesData.rrule;
            }

            await updateDoc(eventRef, allSeriesData);
            break;
        }
      } else {
        // Handle single event update
        await updateDoc(eventRef, baseEventData);
      }

      navigate(-1);
    } catch (error) {
      console.error('Error updating event:', error);
      setError('Error updating event');
    }
  };

  // Helper function to generate new RRULE string
  const generateNewRRule = (eventData) => {
    if (!eventData.recurrence || eventData.recurrence === 'none') return null;

    const timeZone = 'America/Mexico_City';
    const startDate = new Date(eventData.start);
    const endDate = eventData.recurrenceEndDate ? 
      new Date(eventData.recurrenceEndDate) : 
      new Date(startDate.getTime() + (30 * 24 * 60 * 60 * 1000)); // Default to 30 days

    const frequencyMap = {
      'daily': RRule.DAILY,
      'weekly': RRule.WEEKLY,
      'monthly': RRule.MONTHLY
    };

    const options = {
      freq: frequencyMap[eventData.recurrence],
      interval: 1,
      dtstart: startDate,
      until: endDate,
      wkst: RRule.MO
    };

    const rule = new RRule(options);
    const dtstart = `DTSTART:${startDate.toISOString().replace(/[-:]/g, '').replace(/\.\d+Z$/, 'Z')}`;
    return `${dtstart}\nRRULE:${rule.toString()}`;
  };

  const updateSingleEvent = async (eventData, selectedMember, selectedClass) => {
    const dataForFirestore = {
      ...eventData,
      title: eventData.event_title,
      memberName: selectedMember?.contact_name || '',
      class_name: selectedClass?.class_name || '',
      start: eventData.start,
      end: eventData.end,
      createdBy: auth.currentUser.uid
    };

    await updateDoc(doc(db, 'events', eventId), dataForFirestore);
    
    // Fetch and update local state
    const newEventDoc = await getDoc(doc(db, 'events', eventId));
    const newEventData = newEventDoc.data();
    
    setEvent({
      id: newEventDoc.id,
      ...newEventData,
      title: newEventData.title || newEventData.event_title,
      memberName: newEventData.memberName,
      class_name: newEventData.class_name,
      start: newEventData.start?.toDate?.() || new Date(newEventData.start),
      end: newEventData.end?.toDate?.() || new Date(newEventData.end),
      recurrence: newEventData.rrule ? getRecurrenceFromRRule(newEventData.rrule) : null
    });
    
    setShowEditModal(false);
  };

  const getRecurrenceText = (recurrence) => {
    console.log('Recurrence value:', recurrence);
    switch (recurrence) {
      case 'daily':
        return 'Se repite diariamente';
      case 'weekly':
        return 'Se repite semanalmente';
      case 'monthly':
        return 'Se repite mensualmente';
      default:
        return null;
    }
  };

  const handleEditClick = () => {
    if (event.rrule) {
      setShowEditRecurringModal(true);
    } else {
      setShowEditModal(true);
    }
  };

  const handleEditChoice = (choice) => {
    setEditMode(choice);
    setShowEditRecurringModal(false);
    setShowEditModal(true);
  };

  const handleBack = () => {
    const searchParams = new URLSearchParams(location.search);
    const returnView = searchParams.get('returnView');
    const returnDate = searchParams.get('returnDate');

    if (returnView && returnDate) {
      navigate(`/schedule?view=${returnView}&date=${returnDate}`);
    } else {
      // Fallback to event's date in day view
      navigate(`/schedule?view=timeGridDay&date=${event.start.toISOString()}`);
    }
  };

  if (loading) return <div>Cargando...</div>;
  if (error) return <div>{error}</div>;
  if (!event) return <div>Evento no encontrado</div>;

  const formatEventDate = (date) => {
    return format(date, "EEEE, dd 'de' MMMM 'de' yyyy", { locale: es });
  };

  const formatEventTime = (start, end) => {
    return `${format(start, 'H:mm')} - ${format(end, 'H:mm')}`;
  };

  return (
    <div className="event-details-page">
      <Container className="event-details-container">
        <div className="event-content">
          <div className="event-details-header">
            <Button variant="link" onClick={handleBack} className="back-button">
              Volver
            </Button>
            <h1 className="event-title">{event.title || event.class_name}</h1>
            <div className="event-datetime">
              <div className="event-date">{formatEventDate(event.start)}</div>
              <div className="event-time">{formatEventTime(event.start, event.end)}</div>
              {event.recurrence && (
                <div className="event-recurrence">
                  <span className="recurrence-icon">↻</span>
                  {getRecurrenceText(event.recurrence)}
                </div>
              )}
            </div>
            
            {event.videoCall && (
              <div className="video-call-buttons">
                <Button variant="outline-primary" className="video-button">
                  <Camera /> Iniciar videollamada
                </Button>
                <Button 
                  variant="outline-secondary" 
                  onClick={() => navigator.clipboard.writeText(event.videoCall)}
                >
                  Copiar liga
                </Button>
              </div>
            )}
          </div>

          <div className="event-sections">
            {/* Only show patient section if there's a member name */}
            {event.memberName && (
              <section className="event-section">
                <h2>PACIENTES</h2>
                <div className="section-content">
                  <span>{event.memberName}</span>
                  <span className="status-tag">
                    {event.confirmationStatus === 'confirmed' ? 'Confirmado' : 'Pendiente'}
                  </span>
                </div>
              </section>
            )}

            {/* Only show service section if there's a class name */}
            {event.class_name && (
              <section className="event-section">
                <h2>SERVICIOS</h2>
                <div className="section-content">
                  <span>{event.class_name}</span>
                </div>
              </section>
            )}
          </div>
        </div>

        <div className="event-actions">
          <Button variant="outline-danger" onClick={handleDelete} className="delete-button">
            Eliminar
          </Button>
          <Button variant="dark" onClick={handleEditClick} className="edit-button">
            Editar
          </Button>
        </div>

        <AddEventModal
          show={showEditModal}
          handleClose={() => {
            setShowEditModal(false);
            setEditMode(null);
          }}
          handleSave={handleEditSave}
          selectedDate={event?.start}
          classes={classes}
          activeMembers={activeMembers}
          initialData={event}
          editMode={editMode}
        />

        <EditRecurringEventModal
          show={showEditRecurringModal}
          onHide={() => setShowEditRecurringModal(false)}
          onEditChoice={handleEditChoice}
        />

        <DeleteRecurringEventModal
          show={showDeleteModal}
          onHide={() => {
            setShowDeleteModal(false);
            setPendingEdit(null);
          }}
          eventTitle={event?.title || event?.class_name}
          onDelete={handleRecurringDelete}
          mode="delete"
        />
      </Container>
    </div>
  );
};

// Add this helper function to parse RRULE
const getRecurrenceFromRRule = (rrule) => {
  if (!rrule) return null;
  
  // Extract the FREQ parameter from the RRULE string
  const freqMatch = rrule.match(/FREQ=([^;]+)/);
  if (!freqMatch) return null;
  
  const freq = freqMatch[1].toLowerCase();
  switch (freq) {
    case 'daily':
      return 'daily';
    case 'weekly':
      return 'weekly';
    case 'monthly':
      return 'monthly';
    default:
      return null;
  }
};

export default EventDetails;