import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const EditRecurringEventModal = ({ show, onHide, onEditChoice }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Editar evento recurrente</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>¿Cómo deseas editar este evento?</p>
        <div className="d-grid gap-2">
          <Button variant="outline-primary" onClick={() => onEditChoice('single')}>
            Solo esta ocurrencia
          </Button>
          <Button variant="outline-primary" onClick={() => onEditChoice('future')}>
            Esta y futuras ocurrencias
          </Button>
          <Button variant="outline-primary" onClick={() => onEditChoice('all')}>
            Todas las ocurrencias
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditRecurringEventModal; 